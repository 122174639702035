<template>
    <b-modal
            id="modal-xl"
            centered
            size="xl"
            title="Detalle de Seguimiento"
            no-fade
            ok-only
            ok-title="Cerrar"
    >
        <b-table
                striped
                hover
                small
                :items="dataValue"
                :fields="fields"
                responsive
                class="mb-0"
        ></b-table>
        <template #cell(rut_alumno)="data">
            {{ data.item.rut_alumno  ? data.item.rut_alumno : '-'}}
        </template>
        <template #cell(Seguimientos_comentario)="data">
            {{ data.item.Seguimientos_comentario  ? data.item.Seguimientos_comentario : '-'}}
        </template>
        <template #cell(cp_ficha)="data">
            {{ data.item.cp_ficha  ? data.item.cp_ficha : '-'}}
        </template>
        <template #cell(rut_alumno)="data">
            {{ data.item.rut_alumno  ? data.item.rut_alumno : '-'}}
        </template>
    </b-modal>
</template>

<script>
    import { BModal, BButton, BTable, VBModal} from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
    export default {
        components: {
            BButton,
            BModal,
            BTable
        },
        directives: {
            'b-modal': VBModal,
            Ripple,
        },
        props: ['dataValue'],
        name: "ModalDetalleSeguimiento",
        data() {
            return {
                fields: [
                    {key: 'Seguimientos_fecha', label: 'Fecha'},
                    {key: 'Seguimientos_comentario', label: 'Comentario'},
                    {key: 'cp_ficha', label: 'Ficha '},
                    {key: 'rut_alumno', label: 'Rut Alumno'},
                    ]
            }
        }
    }
</script>

<style scoped>

</style>
