<template>
    <b-modal
            id="modal-xl"
            size="xl"
            title="Datos Adicionales"
            no-fade
            ok-only
            ok-title="Cerrar"
    >
        <b-col v-if="false"
                cols="12"
        >
            <table class="mt-2 mt-xl-0 w-100 text-left">
                <tr>
                    <th class="pb-50">
                        <feather-icon
                                icon="TagIcon"
                                class="mr-75"
                        />
                        <span class="font-weight-bold">Nombre</span>
                    </th>
                    <td class="pb-50">
                        {{ dataValue.nombre }}
                    </td>
                </tr>
                <tr>
                    <th class="pb-50">
                        <feather-icon
                                icon="FileTextIcon"
                                class="mr-75"
                        />
                        <span class="font-weight-bold">Descripción</span>
                    </th>
                    <td class="pb-50 text-capitalize">
                        {{ dataValue.descripcion }}
                    </td>
                </tr>
                <tr>
                    <th class="pb-50">
                        <feather-icon
                                icon="HeartIcon"
                                class="mr-75"
                        />
                        <span class="font-weight-bold">Vidas</span>
                    </th>
                    <td class="pb-50 text-capitalize">
                        {{ dataValue.vidas }}
                    </td>
                </tr>
            </table>
        </b-col>
        <b-col cols="12" class="mt-1">
            <h5>Actividades</h5>
            <b-table
                    striped
                    hover
                    small
                    :items="actividades"
                    :fields="fields"
                    responsive
                    class="mb-0"
            >
            </b-table>
        </b-col>

    </b-modal>
</template>

<script>
    import { BModal, BButton, BTable, VBModal} from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
    export default {
        components: {
            BButton,
            BModal,
            BTable
        },
        directives: {
            'b-modal': VBModal,
            Ripple,
        },
        props: ['dataValue', 'actividades'],
        name: "ModalDatosAdicionales",
        data() {
            return {
                fields: [
                    {key: 'nombre_actividad', label: 'Nombre Actividad'},
                    {key: 'puntaje'},
                    {key: 'tiempo'},
                    {key: 'fecha', label: 'Fecha Realiza'},
                ]
            }
        }
    }
</script>

<style scoped>

</style>