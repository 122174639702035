<template>
    <div>
        <b-overlay :show="loading">
            <filtros-reportes reportType="cliente" :is-visible="isVisible" :tutor="tutor" @buscar="buscar"></filtros-reportes>
            <b-card>
                <h4 class="card-title">Reportes Clientes</h4>
                <div v-if="visibleReport">
                    <b-row class="pr-1 pl-1">

                        <b-col cols="12" class="my-1 text-left">
                            <div class="d-flex justify-content-end" v-if="totalRows > 0">
                                <b-button
                                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                        v-b-modal.modal-tall
                                        variant="outline-primary"
                                        @click="columnsState"

                                >
                                    <feather-icon
                                            icon="EyeIcon"
                                            class="mr-50"
                                    />
                                    <span class="align-middle">Mostrar Columnas</span>
                                </b-button>

                                <b-button
                                        v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                                        variant="outline-primary"
                                        type="submit"
                                        class="ml-1"
                                        @click="exportar"
                                >
                                    <feather-icon
                                            icon="DownloadIcon"
                                            class="mr-50"
                                    />
                                    <span class="align-middle">Exportar</span>

                                </b-button>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row class="pl-1" v-if="totalRows > 0">
                        <b-col cols="12"></b-col>
                        <b-col cols="3" class="mt-2">
                            <div class="d-flex align-items-center mb-1 mb-md-0">
                                <label>Mostrar</label>
                                <b-form-select
                                        @change="getList"
                                        id="perPageSelect"
                                        v-model="perPage"
                                        :options="pageOptions"
                                        size="sm"
                                        class="mx-50 col-md-3"
                                ></b-form-select>
                                <label>filas por página</label>
                            </div>
                        </b-col>

                        <b-col cols="5" >
                            <b-pagination
                                    @change="onPageChange"
                                    v-model="page"
                                    :total-rows="totalRows"
                                    :per-page="perPage"
                                    align="center"
                                    class="mt-2 mr-1"
                                    aria-controls="my-table"
                            />
                        </b-col>
                        <b-col  cols="4" class="mt-2 pl-1">
                            <div v-if="totalRows > 0" class="d-flex justify-content-end pr-1">
                                <span class="text-muted" >  Mostrando del {{from}} al {{to}} de {{totalRows}} filas</span>
                            </div>
                        </b-col>
                    </b-row>

                    <div class="d-none">{{mensaje}}</div>
                    <b-col cols="12" v-if="showTable">
                        <b-table
                                striped
                                hover
                                small
                                :per-page="perPage"
                                :items="listado"
                                :fields="columnsVisible"
                                responsive
                                class="mb-0"
                                id="my-table"
                                ref="table"
                                show-empty
                        >
                            <template #cell()="data">
                                {{ data.value ? data.value : '-' }}
                            </template>
                            <template #cell(telefono_contacto)="data">
                                {{ data.item.telefono_contacto == 1 ? "Si" : "No" }}
                            </template>
                            <template #cell(fecha_inicio)="data">
                                <b-badge variant="success">
                                    {{ data.item.fecha_inicio  ? data.item.fecha_inicio : '-'}}
                                </b-badge>
                            </template>
                            <template #cell(fecha_cierre)="data">
                                <b-badge variant="success">
                                    {{ data.item.fecha_cierre  ? data.item.fecha_cierre : '-'}}
                                </b-badge>
                            </template>
                            <template #cell(modality)="data">
                                {{ data.item.modality  ? data.item.modality : '-'}}
                            </template>
                            <template #cell(porciento_avance)="data">
                                <b-badge class="center" :variant="porciento_avance(data.item.porciento_avance)">{{ data.item.porciento_avance  ? data.item.porciento_avance : '-'}}</b-badge>
                            </template>
                            <template #cell(evaluacion_final_fecha)="data">
                                <b-badge v-if="data.item.evaluacion_final_fecha" variant="success">
                                    {{ data.item.evaluacion_final_fecha}}
                                </b-badge>
                                <div v-if="!data.item.evaluacion_final_fecha">-</div>
                            </template>
                            <template #cell(evaluacion_final_fecha)="data">
                                <b-badge variant="success">
                                    {{ data.item.evaluacion_final_fecha  ? data.item.evaluacion_final_fecha : '-'}}
                                </b-badge>
                            </template>
                            <template #cell(ultimo_acceso)="data">
                                <b-badge v-if="data.item.ultimo_acceso" variant="success">
                                    {{ data.item.ultimo_acceso}}
                                </b-badge>
                                <div v-if="!data.item.ultimo_acceso">-</div>
                            </template>
                            <template #cell(detalle_seguimiento)="data">
                                <div class="text-center" v-if="data.item.detalle_seguimiento">
                                    <b-button
                                            id="tooltip-detalle"
                                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                            variant="flat-primary"
                                            class="btn-icon"
                                            @click="showDetalleSeguimiento(data.item.detalle_seguimiento)"
                                    >
                                        <feather-icon icon="EyeIcon"
                                        />
                                    </b-button>
                                    <b-tooltip target="tooltip-detalle" triggers="hover" variant="primary">
                                        Ver detalle seguimiento
                                    </b-tooltip>
                                </div>
                                <div v-else>-</div>
                            </template>
                            <template #cell(data_auxiliar)="data">
                                <div class="text-center" v-if="data.item.data_auxiliar">
                                    <b-button
                                            id="tooltip-adicionales"
                                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                            variant="flat-primary"
                                            class="btn-icon"
                                            @click="showDatosAdicionales(data.item.data_auxiliar)"
                                    >
                                        <feather-icon icon="EyeIcon"
                                        />
                                    </b-button>
                                    <b-tooltip target="tooltip-detalle" triggers="hover" variant="primary">
                                        Ver datos adicionales
                                    </b-tooltip>
                                </div>
                                <div v-else>-</div>
                            </template>
                            <template #empty="scope">
                                <div class="text-center">No existen resultados</div>
                            </template>
                        </b-table>

                    </b-col>

                </div>

            </b-card>
        </b-overlay>
        <modal-ds ref="modal_dtlle" :dataValue="dataDetalle"></modal-ds>
        <modal-da ref="modal_adicionales" :dataValue="dataAdicionales" :actividades="actividades"></modal-da>
        <b-modal ref="modalExport"
                 title="Información"
                 ok-only
                 ok-title="Cerrar">
            <p class="my-4"><b>Procesando el reporte</b>! Recibirá un correo con el enlace para descargar el informe</p>
        </b-modal>
        <b-modal
                id="modal-tall"
                title="Modificar estado de las columnas y orden"
                scrollable
                size="lg"
                ok-variant="outline-primary"
                ok-title="Cerrar"
                ok-only
        >
            <b-row class="pr-1 pl-1">
                <b-col md="12" class="mb-1 text-right">
                    <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="outline-primary"
                            @click="columnasPorDefecto"
                    >
                        <feather-icon
                                icon="CheckSquareIcon"
                                class="mr-50"
                        />
                        Columnas por Defecto
                    </b-button>
                    <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="outline-primary"
                            @click="checkAllColumns"
                            class="ml-1"
                    >
                        <feather-icon
                                icon="CheckSquareIcon"
                                class="mr-50"
                        />
                        Todas las columnas
                    </b-button>
                </b-col>
                <b-col md="6">
                    <h6 class="text-primary">Columnas Visibles</h6>
                    <div v-bind="columnsInvisibles"></div>
                    <div v-bind="columnsVisibles"></div>
                    <draggable
                            :list="columnsVisible"
                            tag="ul"
                            group="people"
                            class="list-group list-group-flush cursor-move"
                            @change="cambio"
                    >
                        <b-list-group-item
                                v-for="listItem in columnsVisible"
                                :key="listItem.key"
                                tag="li"
                        >
                            <div class="d-flex">
                                <div class="ml-25">
                                    <b-card-text class="mb-0 font-weight-bold">
                                        {{ listItem.label }}
                                    </b-card-text>
                                </div>
                            </div>
                        </b-list-group-item>
                    </draggable>
                </b-col>
                <b-col md="6">
                    <h6 class="text-primary">Columnas Ocultas</h6>

                    <draggable
                            :list="columnsInvisible"
                            tag="ul"
                            group="people"
                            class="list-group list-group-flush cursor-move"
                            @change="cambio"
                    >
                        <b-list-group-item
                                v-for="listItem in columnsInvisible"
                                :key="listItem.key"
                                tag="li"
                        >
                            <div class="d-flex">
                                <div class="ml-25">
                                    <b-card-text class="mb-0 font-weight-bold">
                                        {{ listItem.label }}
                                    </b-card-text>
                                </div>
                            </div>
                        </b-list-group-item>
                    </draggable>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
    import FiltrosReportes from '@/components/FiltrosReportes.vue'
    import {
        BTable,
        BProgress,
        BBadge,
        BPagination,
        BRow,
        BCol,
        BFormSelect,
        BTfoot,
        BTr,
        BTh,
        BFormCheckbox,
        BButton,
        BTooltip,
        BListGroupItem,
        BCardText,
        BAvatar
    } from 'bootstrap-vue'
    import draggable from 'vuedraggable'
    import Ripple from 'vue-ripple-directive'
    import ModalDetalleSeguimiento from "@/components/ModalDetalleSeguimiento";
    import ModalDatosAdicionales from "@/components/ModalDatosAdicionales";
    export default {
        name: "ReportesClientes",
        components: {
            BTable,
            BProgress,
            BBadge,
            BPagination,
            BRow,
            BCol,
            BFormSelect,
            BTfoot,
            BTr,
            BTh,
            BFormCheckbox,
            BButton,
            BTooltip,
            BListGroupItem,
            BCardText,
            BAvatar,
            draggable,
            'filtros-reportes': FiltrosReportes,
            'modal-ds': ModalDetalleSeguimiento,
            'modal-da': ModalDatosAdicionales,
        },
        directives: {
            Ripple,
        },
        data() {
            return {
                //loading: false,
                showTable: true,
                tutor: false,
                visibleReport: false,
                perPage: 50,
                reportType: 1,
                currentPageV: 1,
                page: 1,
                list:[],
                columnsData:[],
                columnsDataTemp:[],
                columnsVisible:[],
                columnsInvisible:[],
                dataDetalle:[],
                dataAdicionales:[],
                actividades:[],
                isVisible:true,
                filter: {
                    fechaDesde: null,
                    fechaHasta: null,
                    cursoActivo: 1,
                    ficha: null,
                    ordenCompra: null,
                    aplicacion: null,
                    holding: null,
                    ejecutivo: null,
                    cliente: null,
                    alumno: null,
                    fields:[]
                },
                sortField: 'nombre',
                sortOrder: 'desc',
                defaultSortOrder: 'desc',
                sortDesc: false,
                showColumn: false,
                load: false,
                showColumnText: 'Mostrar Columnas',
                pageOptions: [50, 100, 500, 1000],
                transProps: {
                    // Transition name
                    name: 'flip-list'
                },
            }
        },
        mounted() {
        },
        methods: {
            buscar(filter) {
                this.filter = filter;
                this.isVisible = false
                const h = this.$createElement
                const vNodesMsg = h(
                    'p',
                    {class: ['text-left', 'mb-0']},
                    [
                        ` Uno de estos datos es obligatorio para realizar la busqueda: `,
                        h('strong', 'Curso'),
                        `, `,
                        h('strong', 'Ficha'),
                        ` ú `,
                        h('strong', 'Orden de Compra'),
                    ]
                )
                if (this.filter.aplicacion == null && (this.filter.ficha == null || this.filter.ficha == '')  && (this.filter.ordenCompra == null || '')) {
                    this.$bvToast.toast([vNodesMsg], {
                        title: `ALERTA!!`,
                        variant: 'warning',
                        solid: true,
                    })
                } else {
                    this.getList();
                    this.getColumns();
                }

            },
            onPageChange(page) {
                this.page = page;
                //this.currentPageV = page
                this.getList();
            },
            getList() {
                this.visibleReport = true;
                let filterData = {
                    'type': this.filter.type,
                    'num_ficha': this.filter.ficha,
                    'num_orden_compra': this.filter.ordenCompra,
                    'fecha_desde': this.filter.fechaDesde,
                    'fecha_hasta': this.filter.fechaHasta,
                    'id_holding': this.filter.holding,
                    'id_empresa': this.filter.cliente,
                    'id_aplicacion': this.filter.aplicacion,
                    'id_user_comercial': this.filter.ejecutivo,
                    'alumno': this.filter.alumno,
                    'sort_by': this.sortField,
                    'page': this.page,
                    'perPage': this.perPage,
                }
                this.$store.dispatch('reportesClientes/getList', filterData)
            },
            getColumns() {
                let filterData = {
                    'type': this.filter.type,
                    'num_ficha': this.filter.ficha,
                    'num_orden_compra': this.filter.ordenCompra,
                    'fecha_desde': this.filter.fechaDesde,
                    'fecha_hasta': this.filter.fechaHasta,
                    'id_holding': this.filter.holding,
                    'id_empresa': this.filter.cliente,
                    'id_user_comercial': this.filter.ejecutivo,
                    'id_aplicacion': this.filter.aplicacion,
                    'sort_by': this.sortField,
                    'page': this.page,
                    'perPage': this.perPage,
                }
                this.$store.dispatch('reportesClientes/getColumns', filterData)
                this.load = false
            },
            showColumns() {
                this.showColumn = this.showColumn == true ? false : true;
                this.showColumnText = this.showColumn == true ? 'Ocultar Columnas' : 'Mostrar Columnas';
            },
            columnsDefault() {
                for (let key in this.columnsData) {
                    this.columnsData[key].display = this.columnsData[key].default;
                }
            },
            checkAllColumns() {
                this.load = true
                this.columnsVisible = []
                this.columnsInvisible = []
                this.columns.forEach((item, index) => {
                    item.display = true
                })

                this.columnsVisible = this.columns.filter(h => h.display === true)
                this.columnsInvisible = this.columns.filter(h => h.display === false)
            },
            porciento_avance(value) {
                const number = parseFloat(value)
                if (number < 50) {
                    return 'danger'
                } else if (number >= 50 && number < 100) {
                    return 'warning'
                } else if (number >= 100) {
                    return 'success'
                }
            },
            exportar() {
                if (this.listado.length == 0) {
                    this.$bvToast.toast('Debe realizar una búsqueda antes de exportar', {
                        title: `ALERTA!!`,
                        variant: 'warning',
                        solid: true,
                    })
                } else {
                    this.getExportData();
                }
            },
            getExportData() {
                let fieldsV = [];
                Object.entries(this.columnsVisible).forEach(entry => {
                    const [key, value] = entry;
                    fieldsV.push(value.key);
                });
                let filterData = {
                    'filter': {
                        'type': this.filter.type,
                        'num_ficha': this.filter.ficha,
                        'num_orden_compra': this.filter.ordenCompra,
                        'fecha_desde': this.filter.fechaDesde,
                        'fecha_hasta': this.filter.fechaHasta,
                        'id_holding': this.filter.holding,
                        'id_empresa': this.filter.cliente,
                        'id_aplicacion': this.filter.aplicacion,
                        'alumno': this.filter.alumno,
                        'id_user_comercial': this.filter.ejecutivo,
                        'sort_order': this.sortOrder,
                        'fields': fieldsV,
                    },
                    'page': this.page,
                    'perPage': this.perPage,
                    'sort_by': this.sortField,
                }
                if (this.totalRows > 5000) {
                    //this.$refs.modalExport.show();
                    this.$store.dispatch('reportesClientes/exportAllData', filterData)
                } else {
                    this.$store.dispatch('reportesClientes/exportData', filterData)
                }
            },
            showDetalleSeguimiento(data) {
                this.dataDetalle = Object.values(data)
                this.$refs.modal_dtlle.$children[0].show();
            },
            showDatosAdicionales(data) {
                //this.dataAdicionales = JSON.parse(data)
                this.dataAdicionales = data
                if (this.dataAdicionales.actividades) {
                    this.actividades = this.dataAdicionales.actividades
                } else {
                    this.actividades[0] = this.dataAdicionales
                }

                this.$refs.modal_adicionales.$children[0].show();
            },

            columnsState() {
                this.load = true
                this.columnsVisible = this.columnsVisible.filter(h => h.display === true)
                this.columnsInvisible = this.columnsInvisible.filter(h => h.display === false)
            },
            columnasPorDefecto(){
                this.load = true
                this.columnsData.forEach(function (item, index) {
                    item.display = item.default;
                });

                this.columnsVisible = this.columns.filter(h => h.default === true)
                this.columnsInvisible = this.columns.filter(h => h.default === false)
            },
            cambio(column){
            if(column.added) {
                column.added.element.display = !column.added.element.display
            }
            }
        },

        computed: {
            totalRows() {
                return this.$store.state.reportesClientes.rowsTotal;
            },
            to() {
                return this.$store.state.reportesClientes.to;
            },
            from() {
                return this.$store.state.reportesClientes.from;
            },
            listado() {
                return this.$store.state.reportesClientes.list
            },
            currentPage() {
                return this.$store.state.reportesClientes.currentPage
            },
            loading() {
                return  this.$store.state.reportesClientes.loading;
            },
            columnsVisibles() {
                return this.columnsVisible = this.columnsVisible.length > 0 && this.load ? this.columnsVisible : this.columns.filter(h => h.default === true)
            },
            columnsInvisibles() {
                return this.columnsInvisible = this.load ? this.columnsInvisible : this.columns.filter(h => h.default === false)
            },
            columns() {
                const data = this.$store.state.reportesClientes.columns;
                this.columnsData = []
                this.columnsVisible = []
                this.columnsInvisible = []
                for (const key in data) {
                        const column = {
                            key: data[key].field,
                            label: data[key].title,
                            display: data[key].visible,
                            default: data[key].default,
                        };
                    this.columnsData.push(column)
                }
                return this.columnsData
            },
            filteredHeaders() {
                return this.columns.filter(h => h.display === true)
            },
            mensaje() {
                let mensaje = this.$store.state.reportesClientes.mensaje;
                if(mensaje !== '' ){
                    this.$refs.modalExport.show();
                    this.$store.commit('reportesClientes/setMensaje', '')
                }
                return mensaje
            },
        },
        watch: {

        }
    }

</script>

<style scoped>
</style>
